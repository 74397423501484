import "scss/index.scss";

import { plugin_registry } from 'nk-plugin-registry';
// import your plugins here.

import "header/js/header.js";
import "image/js/image.js";
import "footer_image/js/footer_image.js";
import "footer/js/footer.js";

import "../../contact_button/js/contact_button.js";

plugin_registry.init();

