import "../scss/contact_button.scss";
import {plugin_registry, PluginBase} from "nk-plugin-registry";


@plugin_registry.register("ContactButton")
class ContactButton extends PluginBase {
  constructor($node) {
    super($node);
    this.$form_overlay = $node.querySelector('[data-js-select="form-overlay"]');
    this.$background_shadow = $node.querySelector('[data-js-select="background-shadow"]');
    this.$form_triggers = $node.querySelectorAll('[data-js-select="overlay-trigger"]');
    this.$submit_button = $node.querySelector('[data-js-select="submit-button"]');
    this.$inputs = $node.querySelectorAll('input');
    this.$form = $node.querySelector('form');
    this.$subject = $node.querySelector('[data-js-select="email-subject"]').innerText;
    this.$error_message = $node.querySelector('[data-js-select="error-message"]');
    this._form_visible = false;
  }

  get form_visible() {
    return this._form_visible;
  }

  set form_visible(new_value) {
    this._form_visible = new_value;
    if (this._form_visible) {
      // show form
      this.$form_overlay.setAttribute('aria-hidden', false);
      this.$background_shadow.setAttribute('aria-hidden', false)
    } else {
      // hide form
      this.$form_overlay.setAttribute('aria-hidden', true);
      this.$background_shadow.setAttribute('aria-hidden', true)
    }
  }

  connect($node) {
    super.connect($node);

    this.$submit_button.addEventListener('click', this.submit_form)

    for (const form_trigger of this.$form_triggers) {
      form_trigger.addEventListener('click', this.toggle_form_overlay);
    }

    for (const input of this.$inputs) {
      input.addEventListener('focus', this.clear_required_message);
    }
  }

  clear_required_message = (e) => {
    const input = e.target
    if (input.classList.contains("validation_failed")) {
      input.classList.remove("validation_failed")
      input.value = ""
    }
  }

  validate_email = (email) => {

    const re =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return String(email)
        .toLowerCase()
        .match(re);
  };

  submit_form = async (e) => {

    const post_to_url = this.$form.action;
    const formData = new FormData(this.$form);
    this.$error_message.setAttribute('aria-hidden', true);

    let validatation_failed;

    for (const input of this.$inputs) {

      if (input.name === "email" && !this.validate_email(input.value)) {
        input.classList.add("validation_failed")
        input.value = "Eine Email Adresse wird benötigt"
        validatation_failed = true
      } else if (input.classList.contains("required") && input.value === "") {
        input.value = "Bitte ausfüllen"
        input.classList.add("validation_failed")
        validatation_failed = true
      }
    }

    if (!validatation_failed) {
      const data = Object.fromEntries(formData)
      data.subject = this.$subject

      function getCookie(name) {
        let cookieValue = null;
        if (document.cookie && document.cookie !== '') {
          const cookies = document.cookie.split(';');
          for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
              cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
              break;
            }
          }
        }
        return cookieValue;
      }
      const csrftoken = getCookie('csrftoken');


      const response = await fetch(post_to_url, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          'X-CSRFToken': csrftoken,
          "Content-type": "application/json; charset=UTF-8"
        }
      });

      if (response.ok) {
        window.location.assign("/gesendet/");
      } else {
        this.$error_message.setAttribute('aria-hidden', false);
      }
    }
  }

  toggle_form_overlay = () => {
    this.form_visible = !this.form_visible;
  }
}

export { ContactButton }
